<template>
    <div style="padding:50px; padding-top:10px">
        <div>
            <div class="d-flex align-items-center" style="height:200px; width:100%; ">
                <div class="profile-img">
                    <b-media-aside style="border-redius: 50%">
                        <b-link>
                            <b-img ref="previewEl" rounded src="'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                                " height="80" width="80"  style="margin-left:50px"/>
                        </b-link>
                        <!--/ avatar -->
                    </b-media-aside>
                </div>
                <div style="display:inline">
                    <b-media-body class="mt-75 ml-75 mb-100" >
                        <!-- upload button -->
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="md" class="mt-1 ml-1"
                            @click="profileimageclick()">
                            Upload new photo
                        </b-button>
                        <div style="display: none">
                            <b-form-file v-model="profileFile" id="fileUpload"
                                @input="handleFileChange($event, 'profilephoto')" />
                        </div>
                        <!--/ upload button -->

                        <!-- reset -->
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="md"
                            class="mt-1 ml-1" @click="profile_image = null">
                            reset
                        </b-button>
                        <!--/ reset -->
                    </b-media-body> <br>
                    <small class="ml-75" style="padding:20px">Allowed JPG,GIF or PNG. MAX size of 800K</small>
                </div>
            </div>
        </div>

        <b-form @submit.prevent>
            <b-row>
                <b-col md="6">
                    <b-form-group label="First Name" label-for="mc-first-name">
                        <b-form-input id="mc-first-name" placeholder="First Name" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Last Name" label-for="mc-last-name">
                        <b-form-input id="mc-last-name" placeholder="Last Name" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Phone Number" label-for="mc-phoneno">
                        <b-form-input id="mc-phoneno" placeholder="Phone Number" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Email" label-for="mc-email">
                        <b-form-input id="mc-email" placeholder="Email" />
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group label-for="mc-Address" label="Address">
                        <div class="form-label-group">
                            <b-form-input id="mc-address" type="textarea" placeholder="Address" />
                        </div>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Zip Code" label-for="mc-zipcode">
                        <div class="form-label-group">
                            <b-form-input id="mc-zipcode" placeholder="Zip Code" />
                        </div>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Country" label-for="mc-country">
                        <div class="form-label-group">
                            <b-form-input id="mc-country" placeholder=" Country" />
                        </div>
                    </b-form-group>
                </b-col>
                

               

                <!-- submit and reset -->
                <b-col>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1 ml-1">
                        Save Changes
                    </b-button>
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                        Cancle 
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>
  
  
<script>
import {
    BCard, BImg, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BCard,
        BTabs,
        BButton,
        BNavItem,
        BNavbar,
        BNavbarToggle,
        BCollapse,
        BImg,
    },
    props: {
        headerData: {
            type: Object,
            default: () => { },
        },
    },
    directives: {
        Ripple,
    },
    methods: {
        profileimageclick() {
            document.getElementById("fileUpload").click();
        },
        async handleFileChange(e, type) {
            const accessToken = localStorage.getItem("accessToken");
            const baseApi = process.env.VUE_APP_APIENDPOINT;
            const imagePath = process.env.VUE_APP_IMAGE_PATH;
            // console.log(imagePath, 'imagePath');
            const formData = new FormData();
            formData.append("image", e);
            formData.append("type", type);
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${accessToken}`,
                },
                data: formData,
                url: `${baseApi}/attachment`,
            };
            await axios(requestOptions)
                .then((response) => {
                    // console.log(process.env.IMAGE_PATH,"jay-=-")
                    this.profile_image = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
                })
                .catch((error) => console.log(error, "error"));
        },
    },
}
</script>